import Vue from 'vue'
import Vant from 'vant'
import 'vant/lib/index.css'

import App from './App.vue'

import router from './router'
// import store from './store'

import 'animate.css'
import http from '@/utils/request'
// import 'windi.css'

// 引入整个core-js库
import 'core-js'

// 按需引入core-js特性
import 'core-js/es/promise'

// 引入babel-polyfill库
import 'babel-polyfill'
Vue.use(Vant)
Vue.prototype.$api = http

Vue.config.productionTip = false

new Vue({
  router,
  // store,
  render: (h) => h(App),
}).$mount('#app')
