import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'RH',
    component: () => import('../views/RH'),
    meta: {
      title: '广告机', //横屏
    },
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('../views/home'),
  //   meta: {
  //     title: '广告机',
  //   },
  // },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index'),
    meta: {
      title: '广告机',
    },
  },
  {
    path: '/machine',
    name: 'machine',
    component: () => import('../views/machine'),
    meta: {
      title: '广告机',
    },
  },
  {
    path: '/hs',
    name: 'hs',
    component: () => import('../views/HS'),
    meta: {
      title: '广告机', //横屏
    },
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
